@font-face {
    font-family: Figtree;
    src: url("/fonts/Figtree-Regular.woff2");
    format: ("woff2");
    font-display: swap;
}

@font-face {
    font-family: FigtreeBold;
    src: url("/fonts/Figtree-Bold.woff2");
    format: ("woff2");
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: FigtreeLight;
    src: url("/fonts/Figtree-Light.woff2");
    format: ("woff2");
    font-style: light;
    font-display: swap;
}

@font-face {
    font-family: FigtreeItalic;
    src: url("/fonts/Figtree-Italic.woff2");
    format: ("woff2");
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: FigtreeSemibold;
    src: url("/fonts/Figtree-SemiBold.woff2");
    format: ("woff2");
    font-display: swap;
}

@media print {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li,
    p,
    ul,
    ol,
    span,
    div {
        color: #000 !important;
    }

    body {
        background-color: #ffffff !important;
    }

    main {
        max-width: 100% !important;
    }

    @page {
        margin: 20mm 10mm !important;
    }

    article {
        max-width: auto;
        padding: 0 !important;
    }

    .no-print {
        display: none !important;
    }

    .MuiCollapse-container.MuiCollapse-hidden {
        min-height: auto !important;
        height: auto !important;
        visibility: visible !important;
    }

    .MuiIconButton-root.MuiAccordionSummary-expandIcon {
        display: none;
    }

    .MuiAccordionDetails-root {
        background: transparent !important;
    }

    table {
        overflow: initial !important;
    }

    #CookiebotWidget {
        display: none !important;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.CookieDeclarationTable {
    min-width: 50rem;
}

.CookieDeclarationType {
    overflow-x: auto !important;
}

* {
    box-sizing: border-box;
}

body {
    background-color: #f6f6f6 !important;
    margin: 0;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
}

/* Article styling */

article p {
    padding: 0;
}

article ul,
article ol {
    padding-left: 32px;
    padding-right: 16px;
}

article li {
    padding: 2px 0 !important;
}

article .top-panel > p {
    padding: 0 16px;
}

article .top-panel > .disclosure-panel {
    padding: 0 16px 8px 16px;
}

article h4 {
    margin: 8px 0 !important;
}

article h5 {
    margin: 8px 0 !important;
}

/* Facts sections */
article section.facts-section {
    padding-top: 16px;
    border: 1px solid #9e9e9e;
}

article section .facts-header {
    border-bottom: 1px solid #9e9e9e;
    padding: 16px;
}

article section .facts-content {
    padding: 16px;
}

/* "NHI Standard Tables", table styling from CMS */

article table.nhi-std-title tr:first-child {
    background-color: #f5f5f5;
}

article table.nhi-std-title tr:first-child td {
    font-weight: bold;
}

article table.nhi-std-row-headers tr:nth-child(2) {
    background-color: #f5f5f5;
}

article table.nhi-std-row-headers tr:nth-child(2) td {
    font-weight: bold;
}

article table.nhi-std-column-headers tr td:first-child {
    background-color: #fafafa;
    font-weight: bold;
}

article table.nhi-std-striped tr:nth-child(2n + 4) {
    background-color: #fafafa;
}

article table.smallfont td {
    font-size: 0.75rem;
}

article table.html-table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

article table.html-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

article .disclosure-panel {
    transform-origin: top;
    animation: slide-down 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media print {
    article {
        max-width: auto;
        padding: 0 !important;
    }
}

body {
    background-color: #f6f6f6 !important;
    font-family: Figtree, sans-serif;
}

button {
    font-family: Figtree, sans-serif;
}

::-moz-selection {
    background: #0a7faedd;
    color: white;
}

::selection {
    background: #0a7faedd;
    color: white;
}

#CybotCookiebotDialogPoweredbyCybot {
    display: none !important;
}

@media (max-width: 1280px) {
    #CybotCookiebotDialogBodyContent {
        padding-top: 8px !important;
    }
    #CybotCookiebotDialogHeader {
        display: none !important;
    }

    #CybotCookiebotDialogBodyContentText {
        max-width: 90% !important;
    }
}

/* Preview pulse styling */

@keyframes pulse {
    0% {
        color: "initial";
    }
    50% {
        color: "red";
    }
    100% {
        color: "initial";
    }
}

/* Disclosure component */

@keyframes slide-down {
    0% {
        opacity: 0.2;
        transform: translateY(-10px) scaleY(0);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scaleY(1);
    }
}
